<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <!--  :to1="'/petoverview/new'" -->
        <HeaderGlobal
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          :titlemenu2="titlemenu2"
          :to2="goTo()"
          :titlemenu3="$t('pet_dropdown_menu_care_routine')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center">
      <RoutineTable :pet="this.pet" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import RoutineTable from "./RoutineTable.vue";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    HeaderGlobal,
    RoutineTable,
  },
  data() {
    return { pet: {}, titlemenu2: "" };
  },
  mounted() {
    // if back button is pressed
    window.onpopstate = function (event) {
      alert(
        "location: " +
          document.location +
          ", state: " +
          json.stringify(event.state)
      );
    };
  },
  async created() {
    var retrievedObject = localStorage.getItem("pet");
    const pet = JSON.parse(retrievedObject);

    this.titlemenu2 = nameOfPet(pet.species) + " Record: " + pet.callName;
  },
  methods: {
    goTo() {
      //return this.pet.type == 3 ? "/publicprofile/contactview" : "/petrecord";
    },
  },
};
</script>

<style></style>
