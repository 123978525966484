<template>
  <div>
    <ul class="flex font-proximaLight border-r border-l border-lightGrey20">
      <li
        class="border-r border-b border-lightGrey py-4 w-2/12 flex items-center justify-evenly"
      >
        <span>{{ activityTime }}</span>
      </li>
      <li
        class="border-r border-b border-lightGrey p-4 w-3/12 flex items-center justify-between"
      >
        <div class="w-full flex items-center">
          <div
            class="w-max rounded-full p-2 mr-2"
            :class="timeSensibilityClass"
          ></div>
          <span :class="timeSensibilityTextClass">{{ activityText }}</span>
        </div>
      </li>
      <li class="border-r border-b border-lightGrey py-4 w-7/12 flex flex-col">
        <p class="w-full pl-2 text-left text-sm">
          {{ routineNotes }}
        </p>
      </li>
      <!--    <li
        class="border-b border-lightGrey p-4 w-2/12 flex items-center justify-center"
      >
        <input
          type="checkbox"
          v-model="checkedActivity"
          @change="checkActivity($event)"
          :disabled="checkedActivity == 1"
        />
      </li> -->
    </ul>
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";

import PreviewModals from "../../trainingplan/modals/PreviewModals.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    TrashIcon,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    PreviewModals,
    DatePicker,
  },
  props: ["routine"],
  data() {
    return {
      deleteActModals: false,
      editNotes: false,
      editActivityText: false,
      editActivityTime: false,
      time: null,
      checkedActivity: this.routine.done,
      timeSensibility: this.routine.time_sensitivity,
      activityTime: this.routine.activity_time,
      routineNotes: this.routine.notes,
      activityText: this.routine.activity,
      timeSensibilityClass:
        this.routine.time_sensitivity.id == 1
          ? "bg-red"
          : this.routine.time_sensitivity.id == 2
          ? "bg-orange"
          : "bg-primary",
      timeSensibilityTextClass:
        this.routine.time_sensitivity.id == 1
          ? "text-red"
          : this.routine.time_sensitivity.id == 2
          ? "text-orange"
          : "text-primary",
    };
  },
  methods: {
    checkActivity(i) {
      if (this.checkedActivity == true) {
        this.routine.done = this.checkedActivity;
        this.$emit("editRout", this.routine);
      }
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
[type="checkbox"] {
  width: 2rem;
  height: 2rem;
  color: #0ac0cb;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 4px;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */

[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

[type="checkbox"]:checked {
  background-color: currentcolor;
}

[type="checkbox"]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */

[type="checkbox"]:disabled {
  opacity: 0.84;
  cursor: not-allowed;
}

/* IE */

[type="checkbox"]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type="checkbox"]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}
</style>