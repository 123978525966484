<template>
  <div class="w-full md:w-11/12 lg:w-10/12">
    <div class="flex justify-between items-end">
      <div class="flex items-center text-left font-Light-bold text-2xl">
        <img
          class="mr-2 rounded-full w-24 h-24 object-cover"
          :src="picture"
          :alt="petData.callName"
        />
        <span>{{ petData.callName }}</span>
      </div>
     <!--  <div class="flex items-center font-proximaLight">
        <span class="text-sm">Need help?</span>
        <div
          class="ml-2 bg-primary p-2 rounded-full cursor-pointer"
          @click="helpModals = !helpModals"
        >
          <QuestionmarkIcon width="28px" height="28px" />
        </div>
      </div> -->
    </div>
    <div class="w-1000 md:w-full">
      <div
        class="py-6 px-4 mt-4 bg-primary rounded-tl-md rounded-tr-3xl font-proximaMedium text-white"
      >
        <ul class="flex">
          <li class="w-2/12">{{ $t("global_time") }}</li>
          <li class="w-3/12">{{ $t("global_activity") }}</li>
          <li class="w-5/12">{{ $t("global_notes") }}</li>
          <!--   <li class="w-2/12">{{ $t("global_done") }}</li> -->
        </ul>
      </div>
      <div v-for="(routine, i) in CareRoutineData" :key="i">
        <RoutineTableEach
          :routine="routine"
          @editRout="editRoutine"
          ref="child"
        />
      </div>
    </div>

    <ModalsConfirm :show="helpModals" @close="helpModals = false">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-lg font-proximaSemibold cursor-pointer"
          @click="helpModals = false"
        >
          ✖
        </div>
        <h1 class="mt-4 text-2xl">Time-based Activities</h1>
      </div>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Some activities are time sensitive and trigger reminders. The pet
          owner will also receive a notification if time sensitive activities
          are not completed punctually.
        </span>
        <div class="mt-2 w-full md:w-9/12 h-32 font-proximaLight">
          <div class="flex">
            <div class="mr-1 bg-red w-5 h-5 rounded-full"></div>
            <span>Must be done within 1 hour of the specified time</span>
          </div>
          <div class="flex my-2">
            <div class="mr-1 bg-orange w-5 h-5 rounded-full"></div>
            <span>Should be done within 3 hours of the specified time</span>
          </div>
          <div class="flex">
            <div class="mr-1 bg-primary w-5 h-5 rounded-full"></div>
            <span>Not time sensitive, the time is a guideline</span>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>

    <ModalsConfirm :show="commentModals" @close="commentModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        Add Comment to Activity
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <FormError :errors="errorForm" />
        <div class="mt-2 w-full w-full md:w-9/12 h-32 font-proximaLight">
          <textarea
            @input="addComment"
            placeholder="If you have anything to add about the task, please enter it and click confirm."
            class="mt-2 w-full h-20 p-2 text-sm rounded-sm font-proximaLight"
          ></textarea>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="addCommentCancel"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="addCommentConfirm"
      />
    </ModalsConfirm>
  </div>
</template>

<script>
import RoutineTableEach from "./RoutineTableEach.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import QuestionmarkIcon from "../../icons/QuestionmarkIcon.vue";
import FormError from "../../warning/FormError.vue";
import { mapActions, mapGetters } from "vuex";
import { petPicture, pictureByNameSpecies } from "../../../methods/petMethods";
import {
  getCareRoutineOfPet,
  careRoutineDone,
} from "../../../services/careRoutineService";
import { petRecordById } from "../../../services/petRecordService";

export default {
  components: {
    RoutineTableEach,
    AddIcon,
    SubmitButton,
    ModalsConfirm,
    BorderedButton,
    QuestionmarkIcon,
    FormError,
  },
  data() {
    return {
      pet: {},
      helpModals: false,
      commentModals: false,
      checkAll: false,
      comment: "",
      routineToUpdate: [],
      routines: [
        /*  {
            time: "4:30",
          pets: "all",
          activityColor: "primary",
          activityText: "Lorem ipsum",
          notes:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, blanditiis labore sit corporis voluptate architecto dolores eius. ",
        
        }, */
      ],
    };
  },

  computed: {
    ...mapGetters({
      petProfileData: "petRecord/getPetProfile",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },

    biometricData() {
      return this.petProfileData["Biometric Data"];
    },

    CareRoutineData() {
      console.log("111", this.petProfileData);
      if (this.petProfileData) {
        return this.petProfileData["Care Routine"];
      }
    },
  },

  async created() {
    var retrievedObject = localStorage.getItem("pet");
    this.petData = JSON.parse(retrievedObject);

    this.picture = this.petData.profile_picture
      ? this.petData.profile_picture
      : pictureBySpecies(this.petData);

    this.petData ? this.getPetProfile(this.petData.petID) : "";
  },

  methods: {
    ...mapActions({
      getPetProfile: "petRecord/getPetProfile",
    }),
    async addCommentCancel() {
      const today = new Date();

      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: this.routineToUpdate.id,
        time_done: time, //routine.time_done,
        comment: "",
      };
      await careRoutineDone(data).then(() => {
        this.commentModals = false;
      });
    },

    async addCommentConfirm() {
      const today = new Date();

      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: this.routineToUpdate.id,
        time_done: time, //routine.time_done,
        comment: this.comment,
      };
      await careRoutineDone(data).then(() => {
        this.commentModals = false;
      });
    },

    async addComment(e) {
      this.comment = e.target.value;
    },
    async editRoutine(routine) {
      this.$store.commit("errorForm", "");
      this.commentModals = true;

      this.routineToUpdate = routine;

      /*  const routines = [...this.routines];
      const index = routines.indexOf(routine);
      routines[index] = { ...routine };
      this.routines = routines; */
    },
  },
};
</script>

<style></style>
