<template>
  <div class="my-6">
    <RoutineSection />
  </div>
</template>

<script>
import RoutineSection from "../components/routineoverview/public/RoutineSection.vue";

export default {
  components: {
    RoutineSection,
  },
};
</script>