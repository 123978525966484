<template>
  <div>
    <!-- upload new video modals -->
    <ModalsConfirm :show="previewVideoModals">
      <div slot="actionQuestion" class="relative">
        <!-- <div
          class="
            absolute
            -top-6
            -right-6
            text-lg
            font-proximaSemibold
            cursor-pointer
          "
          @click="previewVideoModals = false"
        >
          ✖
        </div> -->
        <VideoComponent :newVideo="activity.video_link" />
        <!-- <h1 class="mt-4 text-2xl">MOV123.MPEG</h1> -->
      </div>
      <div
        slot="actionText"
        class="
          mb-6
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          :title="$t('global_close')"
          background="bg-primary"
          paddingX="px-4"
          width="w-4/12"
          @click.native="closePreviewVideoModals"
        />
      </div>
      <div slot="leftBtn" class=""></div>
      <div slot="rightBtn" class=""></div>
    </ModalsConfirm>
    <!-- upload new video modals end -->
    <!-- upload voice modals -->
    <ModalsConfirm :show="previewVoiceModals">
      <div slot="actionQuestion" class="relative">
        <!-- <div
          class="
            absolute
            -top-6
            -right-6
            text-lg
            font-proximaSemibold
            cursor-pointer
          "
          @click="previewVoiceModals = false"
        >
          ✖
        </div> -->
        <div>
          <img
            rel="prefetch"
            src="@/assets/images/audio-waves.png"
            alt="audio-waves"
          />
        </div>
        <audio controls class="mx-auto">
          <source src="horse.ogg" type="audio/ogg" />
          <source src="horse.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <h1 class="mt-4 text-2xl">Recording1.mp3</h1>
      </div>
      <div
        slot="actionText"
        class="
          mb-6
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          :title="$t('global_close')"
          background="bg-primary"
          paddingX="px-4"
          width="w-4/12"
          @click.native="closePreviewVoiceModals"
        />
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative"></div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative"></div>
    </ModalsConfirm>
    <!-- upload voice modals end -->
  </div>
</template>

<script>
import VideoComponent from "../VideoComponent.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import MicIcon from "../../icons/MicIcon.vue";
import VideoIcon from "../../icons/VideoIcon.vue";

export default {
  components: {
    VideoComponent,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    MicIcon,
    VideoIcon,
  },
  props: ["activity", "previewVideoModals", "previewVoiceModals"],
  methods: {
    closePreviewVideoModals() {
      this.$emit("closePreviewVideoModals", false);
    },
    closePreviewVoiceModals() {
      this.$emit("closePreviewVoiceModals", false);
    },
  },
};
</script>

<style>
</style>